var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dashboard-container _column"},[(_vm.loading.page)?_c('v-preloader',{staticClass:"_page"}):[_c('v-page-title',_vm._b({},'v-page-title',{
        ..._vm.title,
        breadcrumbs: true,
        customRouteList: _vm.breadcrumbs
      },false)),_c('v-button-group',{attrs:{"type":"tabs-big","narrow":"","new-design":"","dataforce":"","options":[
        {
          name: 'Project setting',
          to: {name: _vm.ROUTE_NAME.PROJECT_CREATE}
        },
        {
          name: _vm.pageName,
          to: _vm.$route.fullPath,
          active: true
        }
      ]}}),_c('v-page-filters',[(_vm.isRoot)?_c('v-page-filter',[_c('s-select',{attrs:{"value":_vm.urlFilters.client_id,"inside-label":"Клиент","options":_vm.clientsAll,"placeholder":"Все","view-options-name":"description","view-options-name-list":"description","search":"","dynamic-width-drop":"","max-width-drop":"255px","max-width":"500px"},on:{"input":_vm.updateFilterClientId}})],1):_vm._e(),_c('v-page-filter',[_c('s-select',{key:`project-filter-${_vm.urlFilters.client_id}`,attrs:{"value":_vm.urlFilters.project_id,"inside-label":"Проект","options":_vm.projects.items,"placeholder":"Все","view-options-name":"label","view-options-name-list":"label","search":"","search-options":_vm.projects.search.items,"lazy-load":_vm.getProjectFilterOptions,"search-load":_vm.getProjectFilterOptions,"clear-button":"","dynamic-width-drop":"","max-width-drop":"255px","max-width":"500px"},on:{"input":function($event){return _vm.updateUrl({project_id: $event})}}})],1)],1),(_vm.loading.list)?_c('v-preloader',{staticClass:"_page"}):_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[(_vm.loading.rest)?_c('s-loader',{staticClass:"loader"}):_vm._e(),(_vm.projectsReportSorted.length)?_vm._l((_vm.projectsReportSorted),function(project){return _c('v-project-item',{key:project.id,attrs:{"project-name":project.label,"project-id":project.id,"report-data":project.report,"project-creation-date":project.created_at}})}):_c('v-no-data')],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }