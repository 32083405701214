<template>
  <div class="project-item-stats">
    <div class="project-item-stats__title">{{ title }}</div>
    <ul :class="['project-item-stats__content', statsData.length > 3 && '_cols-4']">
      <li
        v-for="item of statsData"
        :key="item.id"
        :class="['project-item-stats__item', `_${item.id}`]"
      >
        <span>{{ item.label }}</span>
        <span class="project-item-stats__item-count">
          {{ item.count | formatCount }}<template v-if="item.countOut !== undefined">/{{ item.countOut | formatCount }}</template>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'projectItemStats',

  props: {
    title: {
      type: String,
      default: ''
    },

    statsData: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style scoped lang="scss">
.project-item-stats {
  &__title {
    margin-bottom: 16px;
    line-height: 20px;
    color: #455165;
    font-size: 14px;
    font-weight: 500;
  }

  &__content {
    display: grid;
    grid-template-columns: 1.1fr minmax(125px, 1.9fr) 1.5fr;
    column-gap: 8px;
    height: calc(100% - 36px);
    line-height: 16px;
    color: #424242;
    font-size: 12px;

    &._cols-4 {
      grid-template-columns: minmax(101px, 1.3fr) 1fr minmax(125px, 1.7fr) 1.3fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 12px 8px;
    border-radius: 6px;

    &._listing {
      background-color: #F3EBFF;

      .project-item-stats__item-count {
        color: #7E57C2;
      }
    }

    &._tag_listing {
      background-color: #F1F7FD;

      .project-item-stats__item-count {
        color: #2979FF;
      }
    }

    &._offer {
      background-color: #E2FFEE;

      .project-item-stats__item-count {
        color: #008605;
      }
    }

    &._total {
      .project-item-stats__item-count {
        color: #455165;
      }
    }

    &-count {
      line-height: 20px;
      overflow-wrap: anywhere;
      font-size: 14px;
      font-weight: 500;
      cursor: text;
    }
  }
}
</style>
