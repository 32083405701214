import {mapState, mapActions, mapMutations} from 'vuex'
import {getUser, validateFilter} from '@/function'
import ROUTE_NAME from '@/router/routeName'

import updateUrl from '@/mixins/updateUrl'
import projectItem from './components/projectItem.vue'
import noData from '@/services/errors/noData.vue'

export default {
  name: 'layoutNew',

  metaInfo() {
    return {title: `${this.title.primary} / ${this.title.secondary}`}
  },

  mixins: [updateUrl],

  components: {
    'v-project-item': projectItem,
    'v-no-data': noData
  },

  data() {
    const projectSectionName = 'Project'
    const pageName = 'Project data report'

    return {
      ROUTE_NAME,
      pageName,
      breadcrumbs: [
        {title: projectSectionName},
        {title: 'Project Data'}
      ],

      title: {
        primary: projectSectionName,
        secondary: pageName
      },

      loading: {
        page: false,
        list: false,
        rest: false
      }
    }
  },

  computed: {
    ...mapState({
      clients: state => state.options.clients,
      clientsInactive: state => state.options.clientsInactive,
      projects: state => state.projectsSelect.projects,
      projectsReport: state => state.dashboard.projects.projects,
      profile: state => state.profile
    }),

    isRoot() {
      return getUser().isRoot()
    },

    urlFilters() {
      const routeQuery = this.$route.query
      const filters = {
        client_id: undefined,
        project_id: undefined
      }

      if (this.isRoot && routeQuery.client_id) {
        filters.client_id = validateFilter(routeQuery.client_id, this.clientsAll, true)
      } else {
        filters.client_id = this.profileClientId
      }

      filters.project_id = validateFilter(routeQuery.project_id, this.projects.items, true)

      return filters
    },

    clientsAll() {
      return [...this.clients, ...this.clientsInactive]
    },

    projectsReportSorted() {
      if (this.urlFilters.project_id) {
        return this.projectsReport.items.filter(project => project.id === this.urlFilters.project_id)
      }

      return [
        ...this.projectsReport.items.filter(project => project.report.active),
        ...this.projectsReport.items.filter(project => !project.report.active)
      ]
    },

    profileClientId() {
      return (this.profile && this.profile.client && this.profile.client.id) || undefined
    }
  },

  watch: {
    async $route(to, from) {
      if (to.query.client_id !== from.query.client_id) {
        if (
          (to.query.client_id && from.query.client_id) ||
          (!from.query.client_id && to.query.client_id !== this.profileClientId)
        ) {
          this.clearProjectsFilter()
          await this.getProjectsData()
        }
      }
    }
  },

  async created() {
    this.loading.page = true

    if (this.isRoot) {
      await Promise.all([
        this.getClients(),
        this.getClients({query: {active: '0'}})
      ])
    }

    await this.getProjects({
      client_id: this.urlFilters.client_id,
      all: 1
    })

    const projectIdRoute = this.$route.query.project_id
    if (projectIdRoute && !this.projects.items.find(project => project.id === projectIdRoute)) {
      await this.getProjectFilterOptions({
        projectId: projectIdRoute,
        all: 1
      })
    }

    this.loading.page = false

    await this.getProjectsData()
  },

  methods: {
    ...mapActions({
      getClients: 'getClients',
      getProjects: 'projectsSelect/getProjects',
      getProjectReportList: 'dashboard/projects/getProjects'
    }),

    ...mapMutations({
      clearProjectsReport: 'dashboard/projects/clearProjects',
      clearProjectsFilter: 'projectsSelect/clearProjects'
    }),

    async getProjectsData() {
      this.clearProjectsReport()

      const query = {
        limit: 20,
        all: 1,
        offset: undefined,
        client_id: this.urlFilters.client_id
      }

      this.loading.list = true

      await this.getProjectReportList({query})
        .finally(() => {
          this.loading.list = false
        })

      let restCount = this.projectsReport.total - 20
      if (restCount > 0) {
        this.loading.rest = true
        query.limit = 100
        do {
          query.offset = this.projectsReport.items.length
          await this.getProjectReportList({query})
          restCount -= query.limit
        } while (restCount > 0)
        this.loading.rest = false
      }
    },

    getProjectFilterOptions(data) {
      return this.getProjects({
        ...data,
        client_id: this.urlFilters.client_id,
        all: 1
      })
    },

    updateFilterClientId(value) {
      this.updateUrl({
        client_id: value,
        project_id: undefined
      })
    }
  }
}
