<template>
  <div :class="['project-item', !status.active && '_inactive']">
    <div class="project-item-section">
      <tippy
        interactive
        placement="top-start"
      >
        <template #trigger>
          <div class="project-item__title">
            {{ projectName }}
          </div>
        </template>
        <span class="project-item__title-tooltip">{{ projectName }}</span>
      </tippy>
      <div class="project-item__info">
        <div class="project-item__info-col">
          <div class="project-item__info-item">
            <span class="project-item__label">ID проекта:</span>
            <span>{{ projectId }}</span>
          </div>
          <div class="project-item__info-item">
            <span class="project-item__label">Статус:</span>
            <span :class="['project-item__status', status.active && '_active']">{{ status.label }}</span>
          </div>
        </div>
        <div class="project-item__info-col">
          <div class="project-item__info-item _column">
            <span class="project-item__label">Создание проекта</span>
            <span>{{ projectCreationDateFormatted }}</span>
          </div>
          <div class="project-item__info-item _column">
            <span class="project-item__label">Последнее обновление</span>
            <span>{{ projectLastUpdateDateFormatted }}</span>
          </div>
        </div>
      </div>
    </div>

    <v-project-item-stats
      class="project-item-section"
      title="Количество элементов в проекте"
      :stats-data="statsEntityCountCurrent"
    />

    <v-project-item-stats
      class="project-item-section"
      title="Загружено/удалено элементов"
      :stats-data="statsEntityCountUpdates"
    />
  </div>
</template>

<script>
import {DATE_FORMAT} from '@/const'

import projectItemStats from './projectItemStats.vue'

const ENTITY_TYPE = [
  {
    id: 'listing',
    label: 'Листингов'
  },
  {
    id: 'tag_listing',
    label: 'Теговых листингов'
  },
  {
    id: 'offer',
    label: 'Карточек'
  }
]
export default {
  name: 'projectItem',

  components: {
    'v-project-item-stats': projectItemStats
  },

  props: {
    projectName: {
      type: String,
      default: ''
    },
    reportData: Object,
    projectCreationDate: Number,
    projectId: Number
  },

  computed: {
    status() {
      let data = {active: this.reportData && this.reportData.active}
      data.label = data.active
        ? 'Активен'
        : 'Неактивен'
      return data
    },

    projectCreationDateFormatted() {
      return this.projectCreationDate && !isNaN(+this.projectCreationDate)
        ? this.$moment.utc(+this.projectCreationDate * 1000).format(DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
        : null
    },

    projectLastUpdateDateFormatted() {
      return this.reportData && this.reportData.last_date
        ? this.$moment.utc(this.reportData.last_date, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
        : 'Нет данных'
    },

    statsEntityCountCurrent() {
      return ENTITY_TYPE.map(item => {
        return {
          ...item,
          count: this.reportData && this.reportData[`current_${item.id}s`] || 0
        }
      })
    },

    statsEntityCountUpdates() {
      return [
        {
          id: 'total',
          label: 'Всего',
          count: this.reportData && this.reportData.new_total || 0,
          countOut: this.reportData && this.reportData.out_total || 0
        },
        ...ENTITY_TYPE.map(item => {
          return {
            ...item,
            count: this.reportData && this.reportData[`new_${item.id}s`] || 0,
            countOut: this.reportData && this.reportData[`out_${item.id}s`] || 0
          }
        })
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.project-item {
  display: grid;
  grid-template-columns: 355px minmax(335px, 0.9fr) 1.2fr;
  border: 1px solid #E0E6F0;
  border-radius: 6px;
  cursor: default;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &._inactive {
    opacity: .65;
  }

  &-section {
    padding: 16px;

    &:not(:last-child) {
      border-right: 1px solid #E0E6F0;
    }
  }

  &__title {
    margin-bottom: 8px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2D3646;
    font-size: 16px;
    font-weight: 500;

    &-tooltip {
      overflow-wrap: anywhere;
    }
  }

  &__label {
    color: #7D7D7D;
  }

  &__info {
    display: flex;
    column-gap: 62px;
    color: #455165;

    &-col {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &:first-child {
        justify-content: flex-end;
        row-gap: 16px;
      }

      &:last-child {
        row-gap: 8px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      column-gap: 4px;
      line-height: 16px;
      font-size: 12px;

      &._column {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  &__status {
    padding: 0 4px;
    text-transform: uppercase;
    background-color: #F5F5F5;
    color: #455165;

    &._active {
      background-color: #E2FFEE;
      color: #4CAF50;
    }
  }
}
</style>
